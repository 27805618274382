<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import Decimal from 'decimal.js'

export default {
  name: 'MiniGameForm',
  components: {
    FormPage,
    TextField
  },
  data() {
    return {
      isNew: true,
      formFields: {
        key: {
          type: 'text',
          label: 'Key',
        },
        level: {
          type: 'integer',
          label: 'Level',
        },
        bet: {
          type: 'integer',
          label: 'Bet',
        },
      },
      formValue: {
        key: '',
        level: 1,
        bet: 1000,
        active: true
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New Mini Game' : 'Edit Mini Game'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const miniGames = await this.$store.dispatch('tables/loadTable', {
      key: 'mini_games',
      start: 0
    })
    if (!miniGames.success) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    if (this.$route.name === 'miniGamesAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const wid = this.$route.params.tid
    const el = (miniGames.data || []).find(x => `${x.mini_game_id}` === `${wid}`)
    if (!el) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'mini_games',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Mini Game saved'})
        this.$router.push({name: 'miniGames'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.errors[0]}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'miniGames'})
    },
    rateToNative(val) {
      const d = new Decimal(val)
      const one = new Decimal(1)
      const op = one.dividedBy(d)
      return `1 : ${op.toString()}`
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete Mini Game?',
        message: 'Mini Game information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'mini_games',
        id: this.formValue.mini_game_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'miniGames'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row"><div class="col"><h3>Mini Game</h3></div></div>
    <div class="row">
      <TextField
          v-model="formValue.key"
          :metadata="formFields.key"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.level"
          :metadata="formFields.level"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.bet"
          :metadata="formFields.bet"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="mini-game-active" v-model="formValue.active"/>
        <label for="mini-game-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
