<script>
export default {
  name: 'SideBar',
  methods: {
    logout() {
      this.$store.commit('ui/busy', true)
      this.$store.dispatch('auth/logout')
      this.$store.commit('ui/busy', false)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<template>
<div v-if="$store.getters['auth/isAdmin'] || $store.getters['auth/isRoot']" class="sidebar bg-white ps-3" :class="{
  'd-md-block d-none': !$store.state.ui.mmenu,
  'd-block': $store.state.ui.mmenu
}">
  <ul class="nav flex-column h-100">
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'home'}">Dashboard</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'tshirts'}">T-shirts</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'tokens'}">Tokens</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'weapons'}">Weapons</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'boosters'}">Boosters</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'miniGames'}">Mini Games</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'miniGamesSettings'}">Mini Games Setting</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'miniGamesMTokenSettings'}">Mini Games MToken Setting</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'miniGamesFragmentSettings'}">Mini Games Fragment Setting</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'miniGamesExpSettings'}">Mini Games Free Exp Setting</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'miniGamesBoosterSettings'}">Mini Games Booster Setting</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'clothesRarity'}">Clothes Rarity</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'clothesRarityUpgrade'}">Clothes Rarity Upgrade</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'users'}">Users</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'news'}">News</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{name: 'languages'}">Languages</router-link>
    </li>
    <li class="nav-item flex-fill">&nbsp;</li>
    <li class="nav-item">
      <button class="nav-link btn btn-link" @click="logout()">Log Out</button>
    </li>
  </ul>
</div>
</template>

<style scoped>
.sidebar {
  width: 196px;
}
.nav-link {
  color: #8D8C8F;
}
</style>
