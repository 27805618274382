<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'WeaponsView',
  components: {
    TablePage
  },
  data() {
    return {
      
    }
  },
  async created() {

  },
  methods: {
    addWeapon() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Weapons"
  data-key="weapons"
  :canAdd="true"
  add-button="Add Weapon"
  @add="addWeapon()"
/>
</template>
