<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'

export default {
  name: 'MiniGamesSettingForm',
  components: {
    FormPage,
    TextField
  },
  data() {
    return {
      isNew: true,
      formFields: {
        mtoken_percent: {
          type: 'integer',
          label: 'MToken Percent',
        },
        fragment_percent: {
          type: 'integer',
          label: 'Fragment Percent',
        },
        exp_percent: {
          type: 'integer',
          label: 'Free Exp Percent',
        },
        booster_percent: {
          type: 'integer',
          label: 'Booster Percent',
        },
      },
      formValue: {
        mtoken_percent: 10,
        fragment_percent: 10,
        exp_percent: 50,
        booster_percent: 30,
        active: true
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New Mini Game Setting' : 'Edit Mini Game Setting'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const miniGamesSettings = await this.$store.dispatch('tables/loadTable', {
      key: 'mini_games_settings',
      start: 0
    })
    if (!miniGamesSettings.success) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    if (this.$route.name === 'miniGamesSettingsAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const wid = this.$route.params.tid
    const el = (miniGamesSettings.data || []).find(x => `${x.mini_games_setting_id}` === `${wid}`)
    if (!el) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'mini_games_settings',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Mini Game Setting saved'})
        this.$router.push({name: 'miniGamesSettings'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.errors[0]}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'miniGamesSettings'})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
>
  <div class="container">
    <div class="row"><div class="col"><h3>Mini Game Setting</h3></div></div>
    <div class="row">
      <TextField
          v-model="formValue.mtoken_percent"
          :metadata="formFields.mtoken_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.fragment_percent"
          :metadata="formFields.fragment_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.exp_percent"
          :metadata="formFields.exp_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.booster_percent"
          :metadata="formFields.booster_percent"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="mini-game-setting-active" v-model="formValue.active"/>
        <label for="mini-game-setting-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
