import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import LanguagesView from '@/views/LanguagesView.vue'
import NewsView from '@/views/NewsView.vue'
import TokensView from '@/views/TokensView.vue'
import WeaponsView from '@/views/WeaponsView.vue'
import BoostersView from '@/views/BoostersView.vue'
import ClothesRarityView from '@/views/ClothesRarityView.vue'
import ClothesRarityUpgradeView from '@/views/ClothesRarityUpgradeView.vue'
import MiniGameView from '@/views/MiniGameView.vue'
import MiniGameSettingView from '@/views/MiniGameSettingView.vue'
import MiniGameMTokenSettingView from '@/views/MiniGameMTokenSettingView.vue'
import MiniGameFragmentSettingView from '@/views/MiniGameFragmentSettingView.vue'
import MiniGameExpSettingView from '@/views/MiniGameExpSettingView.vue'
import MiniGameBoosterSettingView from '@/views/MiniGameBoosterSettingView.vue'
import TshirtsView from '@/views/TshirtsView.vue'
import UsersView from '@/views/UsersView.vue'
import LoginView from '@/views/LoginView.vue'

import TokenForm from '@/views/forms/TokenForm.vue'
import WeaponForm from '@/views/forms/WeaponForm.vue'
import BoosterForm from '@/views/forms/BoosterForm.vue'
import ClothesRarityForm from '@/views/forms/ClothesRarityForm.vue'
import ClothesRarityUpgradeForm from '@/views/forms/ClothesRarityUpgradeForm.vue'
import MiniGameForm from '@/views/forms/MiniGameForm.vue'
import MiniGameSettingForm from '@/views/forms/MiniGameSettingForm.vue'
import MiniGameMTokenSettingForm from '@/views/forms/MiniGameMTokenSettingForm.vue'
import MiniGameFragmentSettingForm from '@/views/forms/MiniGameFragmentSettingForm.vue'
import MiniGameExpSettingForm from '@/views/forms/MiniGameExpSettingForm.vue'
import MiniGameBoosterSettingForm from '@/views/forms/MiniGameBoosterSettingForm.vue'
import NewsForm from '@/views/forms/NewsForm.vue'
import TShirtsForm from '@/views/forms/TShirtsForm.vue'
import LanguageForm from '@/views/forms/LanguageForm.vue'
import UserForm from '@/views/forms/UserForm.vue'

import UserPage from '@/views/UserView.vue'

const routes = [{
  path: '/',
  name: 'home',
  component: DashboardView,
  meta: { admin: true }
}, {
  path: '/login',
  name: 'login',
  component: LoginView
}, {
  path: '/languages',
  name: 'languages',
  component: LanguagesView,
  meta: { admin: true }
}, {
  path: '/news',
  name: 'news',
  component: NewsView,
  meta: { admin: true }
}, {
  path: '/tokens',
  name: 'tokens',
  component: TokensView,
  meta: { admin: true }
},{
  path: '/weapons',
  name: 'weapons',
  component: WeaponsView,
  meta: { admin: true }
},{
  path: '/boosters',
  name: 'boosters',
  component: BoostersView,
  meta: { admin: true }
},{
  path: '/mini_games',
  name: 'miniGames',
  component: MiniGameView,
  meta: { admin: true }
},{
  path: '/mini_games_settings',
  name: 'miniGamesSettings',
  component: MiniGameSettingView,
  meta: { admin: true }
},{
  path: '/mini_games_mtoken_settings',
  name: 'miniGamesMTokenSettings',
  component: MiniGameMTokenSettingView,
  meta: { admin: true }
},{
  path: '/mini_games_fragment_settings',
  name: 'miniGamesFragmentSettings',
  component: MiniGameFragmentSettingView,
  meta: { admin: true }
},{
  path: '/mini_games_exp_settings',
  name: 'miniGamesExpSettings',
  component: MiniGameExpSettingView,
  meta: { admin: true }
},{
  path: '/mini_games_booster_settings',
  name: 'miniGamesBoosterSettings',
  component: MiniGameBoosterSettingView,
  meta: { admin: true }
},{
  path: '/clothes_rarity',
  name: 'clothesRarity',
  component: ClothesRarityView,
  meta: { admin: true }
}, {
  path: '/clothes_rarity_upgrade',
  name: 'clothesRarityUpgrade',
  component: ClothesRarityUpgradeView,
  meta: { admin: true }
}, {
  path: '/t-shirts',
  name: 'tshirts',
  component: TshirtsView,
  meta: { admin: true }
}, {
  path: '/users',
  name: 'users',
  component: UsersView,
  meta: { admin: true }
}, {
  path: '/tokens/edit/:tid',
  name: 'tokenEdit',
  component: TokenForm,
  meta: { admin: true }
}, {
  path: '/tokens/add',
  name: 'tokenAdd',
  component: TokenForm,
  meta: { admin: true }
},{
  path: '/weapons/edit/:tid',
  name: 'weaponEdit',
  component: WeaponForm,
  meta: { admin: true }
}, {
  path: '/weapons/add',
  name: 'weaponAdd',
  component: WeaponForm,
  meta: { admin: true }
},{
  path: '/boosters/edit/:tid',
  name: 'boosterEdit',
  component: BoosterForm,
  meta: { admin: true }
}, {
  path: '/boosters/add',
  name: 'boosterAdd',
  component: BoosterForm,
  meta: { admin: true }
},{
  path: '/mini_games/edit/:tid',
  name: 'miniGamesEdit',
  component: MiniGameForm,
  meta: { admin: true }
}, {
  path: '/mini_games/add',
  name: 'miniGamesAdd',
  component: MiniGameForm,
  meta: { admin: true }
},{
  path: '/mini_games_settings/edit/:tid',
  name: 'miniGamesSettingsEdit',
  component: MiniGameSettingForm,
  meta: { admin: true }
}, {
  path: '/mini_games_settings/add',
  name: 'miniGamesSettingsAdd',
  component: MiniGameSettingForm,
  meta: { admin: true }
},{
  path: '/mini_games_mtoken_settings/edit/:tid',
  name: 'miniGamesMTokenSettingsEdit',
  component: MiniGameMTokenSettingForm,
  meta: { admin: true }
}, {
  path: '/mini_games_mtoken_settings/add',
  name: 'miniGamesMTokenSettingsAdd',
  component: MiniGameMTokenSettingForm,
  meta: { admin: true }
},{
  path: '/mini_games_fragment_settings/edit/:tid',
  name: 'miniGamesFragmentSettingsEdit',
  component: MiniGameFragmentSettingForm,
  meta: { admin: true }
}, {
  path: '/mini_games_fragment_settings/add',
  name: 'miniGamesFragmentSettingsAdd',
  component: MiniGameFragmentSettingForm,
  meta: { admin: true }
},{
  path: '/mini_games_exp_settings/edit/:tid',
  name: 'miniGamesExpSettingsEdit',
  component: MiniGameExpSettingForm,
  meta: { admin: true }
}, {
  path: '/mini_games_exp_settings/add',
  name: 'miniGamesExpSettingsAdd',
  component: MiniGameExpSettingForm,
  meta: { admin: true }
},{
  path: '/mini_games_booster_settings/edit/:tid',
  name: 'miniGamesBoosterSettingsEdit',
  component: MiniGameBoosterSettingForm,
  meta: { admin: true }
}, {
  path: '/mini_games_booster_settings/add',
  name: 'miniGamesBoosterSettingsAdd',
  component: MiniGameBoosterSettingForm,
  meta: { admin: true }
},{
  path: '/clothes_rarity/edit/:tid',
  name: 'clothesRarityEdit',
  component: ClothesRarityForm,
  meta: { admin: true }
}, {
  path: '/clothes_rarity/add',
  name: 'clothesRarityAdd',
  component: ClothesRarityForm,
  meta: { admin: true }
}, {
  path: '/clothes_rarity_upgrade/edit/:tid',
  name: 'clothesRarityUpgradeEdit',
  component: ClothesRarityUpgradeForm,
  meta: { admin: true }
}, {
  path: '/clothes_rarity_upgrade/add',
  name: 'clothesRarityUpgradeAdd',
  component: ClothesRarityUpgradeForm,
  meta: { admin: true }
}, {
  path: '/news/edit/:nid',
  name: 'newsEdit',
  component: NewsForm,
  meta: { admin: true }
}, {
  path: '/news/add',
  name: 'newsAdd',
  component: NewsForm,
  meta: { admin: true }
}, {
  path: '/tshirts/edit/:tid',
  name: 'tshirtEdit',
  component: TShirtsForm,
  meta: { admin: true }
}, {
  path: '/languages/edit/:lid',
  name: 'languagesEdit',
  component: LanguageForm,
  meta: { admin: true }
}, {
  path: '/languages/add',
  name: 'languagesAdd',
  component: LanguageForm,
  meta: { admin: true }
}, {
  path: '/users/read/:uid',
  name: 'userRead',
  component: UserPage,
  meta: { admin: true }
}, {
  path: '/users/edit/:uid',
  name: 'userEdit',
  component: UserForm,
  meta: { admin: true }
}, {
  path: '/users/add',
  name: 'userAdd',
  component: UserForm,
  meta: { admin: true }
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
