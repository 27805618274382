<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import DropdownField from '@/components/fields/DropdownField.vue'
import Decimal from 'decimal.js'

export default {
  name: 'ClothesRarityForm',
  components: {
    FormPage,
    DropdownField,
    TextField
  },
  data() {
    return {
      isNew: true,
      formFields: {
        name: {
          type: 'text',
          label: 'Name',
          list: [
            {
              key : 'Common',
              label: 'Common'
            },
            {
              key : 'Uncommon',
              label: 'Uncommon'
            },
            {
              key : 'Rare',
              label: 'Rare'
            },
            {
              key : 'Epic',
              label: 'Epic'
            },
            {
              key : 'Legendary',
              label: 'Legendary'
            }
          ]
        },
        gp_20_percent: {
          type: 'integer',
          label: '20GP %'
        },
        gp_40_percent: {
          type: 'integer',
          label: '40GP %'
        },
        gp_60_percent: {
          type: 'integer',
          label: '60GP %'
        },
        gp_80_percent: {
          type: 'integer',
          label: '80GP %'
        },
        gp_100_percent: {
          type: 'integer',
          label: '100GP %'
        }
      },
      formValue: {
        name: '',
        gp_20_percent: 0,
        gp_40_percent: 0,
        gp_60_percent: 0,
        gp_80_percent: 0,
        gp_100_percent: 0,
        active: true
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New Clothes Rarity' : 'Edit Clothes Rarity'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const clothes_rarities = await this.$store.dispatch('tables/loadTable', {
      key: 'clothes_rarity',
      start: 0
    })
    if (!clothes_rarities.success) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    if (this.$route.name === 'clothesRarityAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const wid = this.$route.params.tid
    const el = (clothes_rarities.data || []).find(x => `${x.clothes_rarity_id}` === `${wid}`)
    if (!el) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'clothes_rarity',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Clothes Rarity saved'})
        this.$router.push({name: 'clothesRarity'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.errors[0]}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'clothesRarity'})
    },
    rateToNative(val) {
      const d = new Decimal(val)
      const one = new Decimal(1)
      const op = one.dividedBy(d)
      return `1 : ${op.toString()}`
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete Clothes Rarity?',
        message: 'Clothes Rarity information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'clothes_rarity',
        id: this.formValue.clothes_rarity_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'clothesRarity'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row"><div class="col"><h3>Clothes Rarity Settings</h3></div></div>
    <div class="row">
      <DropdownField
          v-model="formValue.name"
          :metadata="formFields.name"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.gp_20_percent"
          :metadata="formFields.gp_20_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.gp_40_percent"
          :metadata="formFields.gp_40_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.gp_60_percent"
          :metadata="formFields.gp_60_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.gp_80_percent"
          :metadata="formFields.gp_80_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.gp_100_percent"
          :metadata="formFields.gp_100_percent"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="clothes-rarity-active" v-model="formValue.active"/>
        <label for="clothes-rarity-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
