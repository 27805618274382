<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'MiniGameFragmentSettingView',
  components: {
    TablePage
  },
  data() {
    return {

    }
  },
  async created() {

  },
  methods: {
    addMiniGameFragmentSetting() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Mini game fragment setting"
  data-key="mini_games_fragment_settings"
  :canAdd="true"
  add-button="Add Mini Game Fragment Setting"
  @add="addMiniGameFragmentSetting()"
/>
</template>
