<script>
import dayjs  from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import Decimal from 'decimal.js'

dayjs.extend(timezone)

const COLUMNS = {
  tokens: ['token_id', 'with_icon', 'meta_name', 'meta_ticker', 'native_ticker', 'native_rate', 'updated_at', 'is_virtual', 'active'],
  weapons: ['weapon_id', 'level', 'm_tokens', 'l_fragments', 'multiplier'],
  boosters: ['booster_id', 'name', 'key'],
  mini_games: ['mini_game_id', 'key', 'level', 'bet'],
  mini_games_settings: ['mini_games_setting_id', 'mtoken_percent', 'fragment_percent', 'exp_percent', 'booster_percent'],
  mini_games_mtoken_settings: ['mini_games_mtoken_setting_id', 'from_range', 'to_range', 'multiplier'],
  mini_games_fragment_settings: ['mini_games_fragment_setting_id', 'from_range', 'to_range', 'c_fragment_percent', 'uc_fragment_percent', 'r_fragment_percent', 'e_fragment_percent', 'l_fragment_percent'],
  mini_games_exp_settings: ['mini_games_exp_setting_id', 'from_range', 'to_range', 'multiplier'],
  mini_games_booster_settings: ['mini_games_booster_setting_id', 'from_range', 'to_range', 'c_fragment_percent', 'uc_fragment_percent', 'r_fragment_percent', 'e_fragment_percent', 'l_fragment_percent'],
  clothes_rarity: ['clothes_rarity_id', 'name'],
  clothes_rarity_upgrade: ['clothes_rarity_id', 'from_rarity', 'to_rarity'],
  news: ['news_id', 'has_banner', 'name', 'text', 'url', 'active'],
  tshirts: ['tshirt_id', 'tshirt_type', 'updated_at', 'active'],
  users: ['user_id', 'email', 'role', 'tshirts', 'registration_date', 'last_updated'],
  languages: ['language_id', 'icon', 'name', 'code', 'translated', 'active']
}

const IDKEYS = {
  tokens: 'token_id',
  boosters: 'booster_id',
  weapons: 'weapon_id',
  clothes_rarity: 'clothes_rarity_id',
  clothes_rarity_upgrade: 'clothes_rarity_upgrade_id',
  mini_games: 'mini_game_id',
  mini_games_settings: 'mini_games_setting_id',
  mini_games_mtoken_settings: 'mini_games_mtoken_setting_id',
  mini_games_fragment_settings: 'mini_games_fragment_setting_id',
  mini_games_exp_settings: 'mini_games_exp_setting_id',
  mini_games_booster_settings: 'mini_games_booster_setting_id',
  news: 'news_id',
  tshirts: 'tshirt_id',
  users: 'user_id',
  languages: 'language_id'
}

const NAMES = {
  token_id: 'ID',
  meta_name: 'Meta Name',
  meta_ticker: 'Meta Ticker',
  native_ticker: 'Native Ticker',
  native_rate: 'Exchange Rate',
  updated_at: 'Updated At',
  is_virtual: 'Is Virtual',
  active: 'Is Active',

  news_id: 'ID',
  name: 'Name',
  text: 'Text',
  url: 'Link url',

  tshirt_id: 'ID',
  tshirt_type: 'Type',

  booster_id: 'ID',

  weapon_id: 'ID',
  level: 'Level',
  m_tokens: 'M Tokens',
  l_fragments: 'L Fragments',
  multiplier: 'Multiplier',

  clothes_rarity_id: 'ID',

  clothes_rarity_upgrade_id: 'ID',
  from_rarity: 'From rarity',
  to_rarity: 'To rarity',

  mini_game_id: 'ID',
  key: 'Key',
  bet: 'Bet',

  mini_games_setting_id: 'ID',
  mtoken_percent: 'MToken percent',
  fragment_percent: 'Fragment percent',
  exp_percent: 'Free exp percent',
  booster_percent: 'Booster percent',

  mini_games_mtoken_setting_id: 'ID',
  mini_games_fragment_setting_id: 'ID',
  mini_games_exp_setting_id: 'ID',
  mini_games_booster_setting_id: 'ID',
  from_range: 'From range',
  to_range: 'To range',
  c_fragment_percent: 'Common fragment percent',
  uc_fragment_percent: 'Uncommon fragment percent',
  r_fragment_percent: 'Rare fragment percent',
  e_fragment_percent: 'Epic fragment percent',
  l_fragment_percent: 'Legendary fragment percent',

  user_id: 'ID',
  email: 'E-mail',
  role: 'Role',
  tshirts: 'T-shirt type',
  registration_date: 'Registered At',
  last_updated: 'Updated At',

  language_id: 'ID',
  code: 'Code',
  translated: 'Is Translated',
}

const IMGKEYS = {
  with_icon: 'token_id',
  has_banner: 'news_id',
  icon: 'language_id'
}

const DATEKEYS = ['updated_at', 'registration_date']

const BACKEXCH = ['native_rate']

const ROLEKEYS = ['role']

const TSHIRTKEYS = ['tshirts']

export default {
  name: 'TablePage',
  props: ['title', 'canAdd', 'canRead', 'addButton', 'dataKey', 'searchField'],
  emits: ['add'],
  data() {
    return {
      schema: [],
      elements: [],
      start: 0,
      limit: 10,
      total: 0,
      menus: {},
      searchValue: ''
    }
  },
  computed: {
    columns() {
      return COLUMNS[this.dataKey] || []
    },
    imgBase() {
      return process.env.VUE_APP_IMG_BASE + '/' + this.dataKey
    },
    hasPagination() {
      return this.limit < this.total
    },
    paginationPages() {
      return Math.ceil(this.total/this.limit)
    },
    paginationCurrent() {
      let ret = 0
      for (let i = 0; i < this.paginationPages; i++) {
        if (i * this.limit === this.start)
          ret = i
      }
      return ret
      // return this.start === 0 ? 0 : (Math.floor(this.total/this.start) - 1)
    },
    paginationHasPrev() {
      return this.hasPagination && this.start > 0
    },
    paginationButtons() {
      const current = this.paginationCurrent
      const ret = []
      if (current > 0)
        ret.push(current - 1)
      ret.push(current)
      if (current < (this.paginationPages - 1))
        ret.push(current + 1)
      return ret
    }
  },
  async created() {
    await this.reloadTable()
  },
  methods: {
    async reloadTable() {
      this.$store.commit('ui/busy', true)
      const reqData = {
        key: this.dataKey,
        start: this.start,
        limit: this.limit
      }
      if (this.searchValue.length)
        reqData.search = this.searchValue
      const table = await this.$store.dispatch('tables/loadTable', reqData)
      if (table && table.success) {
        this.schema = table.schema
        this.elements = table.data
        this.total = table.total
      } else {
        this.schema = []
        this.elements = []
        this.start = 0
        this.limit = 10
        this.total = 0
      }
      this.$store.commit('ui/busy', false)
    },
    cName(col) {
      return NAMES[col] || col
    },
    imgKey(col) {
      return IMGKEYS[col]
    },
    isDate(col) {
      return DATEKEYS.includes(col)
    },
    isRole(col) {
      return ROLEKEYS.includes(col)
    },
    isRoleUser(el) {
      return !el.is_admin && !el.is_root
    },
    isRoleAdmin(el) {
      return el.is_admin && !el.is_root
    },
    isRoleRoot(el) {
      return el.is_root
    },
    isTShirt(col) {
      return TSHIRTKEYS.includes(col)
    },
    isBackExch(col) {
      return BACKEXCH.includes(col)
    },
    backExch(val, el) {
      if (el.is_virtual) {
        if (el.virtual_rate && el.virtual_ticker_rate)
          return `${el.virtual_rate} : ${el.virtual_ticker_rate}`
        else
          return 'No exchange'
      } else {
        if (!val) return 'Unknown'
        const d = new Decimal(val)
        const one = new Decimal(1)
        const op = one.dividedBy(d)
        return `1 : ${op.toString()}`
      }
    },
    dateField(val) {
      const d = dayjs(val)
      return d.format('DD-MM-YYYY HH:mm:ss Z')
    },
    tshirtsField(val) {
      const classic = (val.tshirts || []).reduce((acc, ts) => ts.type === 'classic' ? acc + ts.amount : acc, 0)
      const premium = (val.tshirts || []).reduce((acc, ts) => ts.type === 'premium' ? acc + ts.amount : acc, 0)
      return { classic, premium }
    },
    rowClick(eli) {
      const el = this.elements[eli]
      if (this.canRead) {
        // show sub menu
        setTimeout(() => {
          this.menus[eli] = !this.menus[eli]
        }, 0)
      } else {
        // route to edit
        const idf = IDKEYS[this.dataKey]
        const url = `/${this.dataKey}/edit/${el[idf]}`
        this.$router.push(url)
      }
    },
    elAdd() {
      const url = `/${this.dataKey}/add`
      this.$router.push(url)
    },
    elRead(eli) {
      const el = this.elements[eli]
      const idf = IDKEYS[this.dataKey]
      const url = `/${this.dataKey}/read/${el[idf]}`
      this.$router.push(url)
    },
    elEdit(eli) {
      const el = this.elements[eli]
      const idf = IDKEYS[this.dataKey]
      const url = `/${this.dataKey}/edit/${el[idf]}`
      this.$router.push(url)
    },
    clickOutside(eli) {
      return () => {
        if (this.menus[eli])
          this.menus[eli] = false
      }
    },
    paginationGoTo(page) {
      console.log('goto page', page, this.start)
      this.start = page * this.limit
      this.reloadTable()
    },
    doSearch() {
      this.start = 0
      this.reloadTable()
    }
  }
}
</script>

<template>
  <div class="table-page bg-white m-3">
    <div class="table-page-header d-flex p-3 border-bottom border-dark">
      <h2 class="flex-fill m-auto">{{title}}</h2>
      <div class="d-flex">
        <div v-if="searchField" class="input-group me-3 flex-shrink-1">
          <input type="text" class="form-control" :placeholder="searchField" v-model="searchValue">
          <button :disabled="!searchValue.length" class="btn btn-outline-secondary" type="button" @click="doSearch()">Search</button>
        </div>
        <button :disabled="$uiBusy" v-if="canAdd" @click="elAdd()" class="btn btn-primary text-nowrap">
          {{addButton}}
        </button>
      </div>
    </div>
    <div v-if="!total" class="p-3 text-center">
      No Items
    </div>
    <div v-else class="table-body">
      <table class="table">
        <tbody>
          <tr v-for="el, eli of elements" :key="eli">
            <td v-for="col, coli of columns" :key="coli" class="p-3">
              <div v-if="!imgKey(col)" class="col-name text-muted">{{cName(col)}}</div>
              <div v-if="isBackExch(col)" class="col-val">{{backExch(el[col], el)}}</div>
              <div v-if="isDate(col)" class="col-val">{{dateField(el[col])}}</div>
              <img v-if="!!imgKey(col) && el[col]" :src="`${imgBase}/${el[imgKey(col)]}.png`"/>
              <div v-if="isRole(col)" class="col-val">
                <span v-if="isRoleUser(el)">User</span>
                <span v-if="isRoleAdmin(el)">Admin</span>
                <span v-if="isRoleRoot(el)">Super Admin</span>
              </div>
              <div v-if="isTShirt(col)">
                <div v-if="!tshirtsField(el).classic && !tshirtsField(el).premium">
                  No t-shirts
                </div>
                <div v-else>
                  <span v-if="tshirtsField(el).classic" class="me-2">Classic: {{tshirtsField(el).classic}}</span>
                  <span v-if="tshirtsField(el).premium">Premium: {{tshirtsField(el).premium}}</span>
                </div>
              </div>
              <div v-if="
                !imgKey(col) &&
                !isBackExch(col) &&
                !isDate(col) &&
                !isTShirt(col) &&
                !isRole(col)
              " class="col-val">{{el[col]}}</div>
            </td>
            <td class="position-relative text-end p-3">
              <button @click="rowClick(eli)" class="btn btn-link">
                <img src="/hdots.png"/>
              </button>
              <div v-click-outside="clickOutside(eli)" class="position-absolute dropdown-menu dropdown-actions" :class="{
                'show': !!menus[eli]
              }">
                <button class="dropdown-item" @click="elRead(eli)">Read</button>
                <button class="dropdown-item" @click="elEdit(eli)">Edit</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="hasPagination" class="table-footer p-3">
      <nav>
        <ul class="pagination justify-content-end">
          <li class="page-item">
            <button class="page-link" :class="{'disabled': paginationCurrent === 0}" @click="paginationGoTo(0)">First</button>
          </li>
          <li
            v-for="pbi of paginationButtons"
            :key="pbi"
            class="page-item"
            :class="{'active': pbi === paginationCurrent}"
          ><button class="page-link" @click="paginationGoTo(pbi)">{{pbi + 1}}</button></li>
          <li class="page-item">
            <button class="page-link" :class="{'disabled': paginationCurrent === paginationPages - 1}" @click="paginationGoTo(this.paginationPages - 1)">Last ({{this.paginationPages}})</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped>
.dropdown-actions {
  right: 0;
}
</style>
