<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'ClothesRarityView',
  components: {
    TablePage
  },
  data() {
    return {
      
    }
  },
  async created() {

  },
  methods: {
    addClothesRarity() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Clothes Rarity"
  data-key="clothes_rarity"
  :canAdd="true"
  add-button="Add Clothes Rarity"
  @add="addClothesRarity()"
/>
</template>
