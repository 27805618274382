<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'BoostersView',
  components: {
    TablePage
  },
  data() {
    return {
      
    }
  },
  async created() {

  },
  methods: {
    addBooster() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Boosters"
  data-key="boosters"
  :canAdd="true"
  add-button="Add Booster"
  @add="addBooster()"
/>
</template>
