<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'MiniGameBoosterSettingView',
  components: {
    TablePage
  },
  data() {
    return {

    }
  },
  async created() {

  },
  methods: {
    addMiniGameBoosterSetting() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Mini game booster setting"
  data-key="mini_games_booster_settings"
  :canAdd="true"
  add-button="Add Mini Game Booster Setting"
  @add="addMiniGameBoosterSetting()"
/>
</template>
