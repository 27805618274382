<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import DropdownField from '@/components/fields/DropdownField.vue'
import Decimal from 'decimal.js'

export default {
  name: 'ClothesRarityUpgradeForm',
  components: {
    FormPage,
    DropdownField,
    TextField
  },
  data() {
    return {
      isNew: true,
      formFields: {
        from_rarity: {
          type: 'text',
          label: 'From rarity',
          list: [
            {
              key : 'Common',
              label: 'Common'
            },
            {
              key : 'Uncommon',
              label: 'Uncommon'
            },
            {
              key : 'Rare',
              label: 'Rare'
            },
            {
              key : 'Epic',
              label: 'Epic'
            },
            {
              key : 'Legendary',
              label: 'Legendary'
            }
          ]
        },
        to_rarity: {
          type: 'text',
          label: 'To rarity',
          list: [
            {
              key : 'Common',
              label: 'Common'
            },
            {
              key : 'Uncommon',
              label: 'Uncommon'
            },
            {
              key : 'Rare',
              label: 'Rare'
            },
            {
              key : 'Epic',
              label: 'Epic'
            },
            {
              key : 'Legendary',
              label: 'Legendary'
            }
          ]
        },
        c_fragments: {
          type: 'integer',
          label: 'Common Fragments'
        },
        uc_fragments: {
          type: 'integer',
          label: 'Uncommon Fragments'
        },
        r_fragments: {
          type: 'integer',
          label: 'Rare Fragments'
        },
        e_fragments: {
          type: 'integer',
          label: 'Epic Fragments'
        },
        l_fragments: {
          type: 'integer',
          label: 'Legendary Fragments'
        }
      },
      formValue: {
        from_rarity: '',
        to_rarity: '',
        c_fragments: 0,
        uc_fragments: 0,
        r_fragments: 0,
        e_fragments: 0,
        l_fragments: 0,
        active: true
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New Clothes Rarity Upgrade' : 'Edit Clothes Rarity Upgrade'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const clothes_rarity_upgrades = await this.$store.dispatch('tables/loadTable', {
      key: 'clothes_rarity_upgrade',
      start: 0
    })
    if (!clothes_rarity_upgrades.success) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    if (this.$route.name === 'clothesRarityUpgradeAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const wid = this.$route.params.tid
    const el = (clothes_rarity_upgrades.data || []).find(x => `${x.clothes_rarity_upgrade_id}` === `${wid}`)
    if (!el) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'clothes_rarity_upgrade',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Clothes Rarity Upgrade saved'})
        this.$router.push({name: 'clothesRarityUpgrade'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.errors[0]}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'clothesRarityUpgrade'})
    },
    rateToNative(val) {
      const d = new Decimal(val)
      const one = new Decimal(1)
      const op = one.dividedBy(d)
      return `1 : ${op.toString()}`
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete Clothes Rarity Upgrade?',
        message: 'Clothes Rarity Upgrade information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'clothes_rarity_upgrade',
        id: this.formValue.clothes_rarity_upgrade_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'clothesRarityUpgrade'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row"><div class="col"><h3>Clothes Rarity Upgrade Settings</h3></div></div>
    <div class="row">
      <DropdownField
          v-model="formValue.from_rarity"
          :metadata="formFields.from_rarity"
      />
      <DropdownField
          v-model="formValue.to_rarity"
          :metadata="formFields.to_rarity"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.c_fragments"
          :metadata="formFields.c_fragments"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.uc_fragments"
          :metadata="formFields.uc_fragments"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.r_fragments"
          :metadata="formFields.r_fragments"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.e_fragments"
          :metadata="formFields.e_fragments"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.l_fragments"
          :metadata="formFields.l_fragments"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="clothes-rarity-upgrade-active" v-model="formValue.active"/>
        <label for="clothes-rarity-upgrade-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
