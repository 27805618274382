<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'MiniGameView',
  components: {
    TablePage
  },
  data() {
    return {
      
    }
  },
  async created() {

  },
  methods: {
    addMiniGame() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Mini games"
  data-key="mini_games"
  :canAdd="true"
  add-button="Add Mini Game"
  @add="addMiniGame()"
/>
</template>
