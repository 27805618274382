<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'MiniGameSettingView',
  components: {
    TablePage
  },
  data() {
    return {
      canAdd: true
    }
  },
  async created() {
    const miniGamesSettings = await this.$store.dispatch('tables/loadTable', {
      key: 'mini_games_settings',
      start: 0
    })
    this.canAdd = miniGamesSettings.data.length < 1
  },
  methods: {
    addMiniGameSetting() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Mini games setting"
  data-key="mini_games_settings"
  :canAdd="canAdd"
  add-button="Add Mini Game Setting"
  @add="addMiniGameSetting()"
/>
</template>
