<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'

export default {
  name: 'MiniGameBoosterSettingForm',
  components: {
    FormPage,
    TextField
  },
  data() {
    return {
      isNew: true,
      formFields: {
        from_range: {
          type: 'integer',
          label: 'From range',
        },
        to_range: {
          type: 'integer',
          label: 'To range',
        },
        c_fragment_percent: {
          type: 'integer',
          label: 'Common Booster Percent'
        },
        uc_fragment_percent: {
          type: 'integer',
          label: 'Uncommon Booster Percent'
        },
        r_fragment_percent: {
          type: 'integer',
          label: 'Rare Booster Percent'
        },
        e_fragment_percent: {
          type: 'integer',
          label: 'Epic Booster Percent'
        },
        l_fragment_percent: {
          type: 'integer',
          label: 'Legendary Booster Percent'
        }
      },
      formValue: {
        from_range: 0,
        to_range: 200,
        c_fragment_percent: 0,
        uc_fragment_percent: 0,
        r_fragment_percent: 0,
        e_fragment_percent: 0,
        l_fragment_percent: 0,
        active: true
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New Mini Game Booster Setting' : 'Edit Mini Game Booster Setting'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const miniGamesBoosterSetting = await this.$store.dispatch('tables/loadTable', {
      key: 'mini_games_booster_settings',
      start: 0
    })
    if (!miniGamesBoosterSetting.success) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    if (this.$route.name === 'miniGamesBoosterSettingsAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const wid = this.$route.params.tid
    const el = (miniGamesBoosterSetting.data || []).find(x => `${x.mini_games_booster_setting_id}` === `${wid}`)
    if (!el) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'mini_games_booster_settings',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Mini Game Booster Setting saved'})
        this.$router.push({name: 'miniGamesBoosterSettings'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.errors[0]}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'miniGamesBoosterSettings'})
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete Mini Game Booster Setting?',
        message: 'Mini Game Booster Setting information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'mini_games_booster_settings',
        id: this.formValue.mini_games_booster_setting_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'miniGamesBoosterSettings'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row"><div class="col"><h3>Mini Game Booster Setting</h3></div></div>
    <div class="row">
      <TextField
          v-model="formValue.from_range"
          :metadata="formFields.from_range"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.to_range"
          :metadata="formFields.to_range"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.c_fragment_percent"
          :metadata="formFields.c_fragment_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.uc_fragment_percent"
          :metadata="formFields.uc_fragment_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.r_fragment_percent"
          :metadata="formFields.r_fragment_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.e_fragment_percent"
          :metadata="formFields.e_fragment_percent"
      />
    </div>
    <div class="row">
      <TextField
          v-model="formValue.l_fragment_percent"
          :metadata="formFields.l_fragment_percent"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="mini-game-booster-setting-active" v-model="formValue.active"/>
        <label for="mini-game-booster-setting-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
